import { useEffect, } from 'react'
import ReactGA from 'react-ga'

const GoogleAnalytics = () => {
  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }, [])

  return () => {}
}

export default GoogleAnalytics
