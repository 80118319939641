import React from 'react'
import { Helmet, } from 'react-helmet'
import Box from 'components/Box'
import BrainwaveCircuitsImage from 'images/brainwave-circuits.png'
import Header1 from 'typography/Header1'
import HeroImage from 'images/blue-vs-orange-circuits.png'
import Image from 'components/Image'
import Section from 'components/Section'
import Text from 'typography/Text'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

const MissionView = () => {
  const googleAnalytics = useGoogleAnalytics()
  googleAnalytics()

  return (
    <Section>
      <Helmet>
        <title>NetPlay Mission</title>
        <link rel="canonical" href="https://www.netplay.gg/mission"/>
        <meta property="og:description" content="NetPlay, a data and analytics platform that uses software to bolster and improve performance among esports athletes. We combine machine learning and powerful algorithms to match the experience to each individual gamer pushing their skills to the limit."/>
        <meta property="og:image" content={HeroImage}/>
        <meta property="og:title" content="NetPlay Mission"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.netplay.gg/mission"/>
        <meta name="author" content="NetPlay"/>
        <meta name="description" content="NetPlay's Mission Statement. NetPlay is a data and analytics platform that uses software to bolster and improve performance among esports athletes. We combine machine learning and powerful algorithms to match the experience to each individual gamer pushing their skills to the limit."/>
        <meta name="keywords" content="accuracy,aimbot,call of duty,click test,cs:go,esports,fortnite,game training,gamer,gamer training,gaming,keyboard,mechanics,mouse,mouse accuracy,mouse aim,mouse aim training,mouse exercises,mouse click test,mouse practice,mouse training games,overwatch,pubg,reflex training,training,valorant"/>
      </Helmet>
      <Box
        className="flex flex-col h-auto min-h-screen items-center justify-center relative w-full"
        style={{
          backgroundAttachment: 'fixed',
          backgroundImage: `url(${HeroImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <Box className="absolute bg-black-transparent-3 lg:bg-black-transparent-4 h-full left-0 top-0 w-full"/>
        <Box className="mx-auto max-w-8xl xxl:max-w-10xl mt-8 sm:mt-0 px-6 sm:px-32 xxl:px-0 py-8 lg:py-24 z-10">
          <Header1
            className="font-medium font-gotham-black leading-tight sm:mt-8 lg:mt-10 xxl:mt-0 text-2xl sm:text-4xl lg:text-7xl xxl:text-9xl text-center text-white"
            // style={{ 'word-spacing': '30px' }}
          >
            NetPlay's Mission
          </Header1>
          <Box className="flex sm:flex-col-reverse xl:flex-row items-center mt-8 lg:mt-16 xxl:mt-32">
            <Box className="lg:bg-black xxl:bg-black flex flex-1 flex-col font-gotham-book xl:mr-12 sm:mt-12 xl:mt-0 lg:px-8 lg:py-6 rounded xxl:text-xl max-w-2xl">
              <Text className="">
                &emsp;NetPlay is a company of gamers that exists to aid all dedicated gamers on their path to greatness. Our goal is to help you help yourself in progressing your gameplay and skill within any video game or esport.
              </Text>
              <Text className="mt-6">
                &emsp;By using rigorous analysis, gameplay mechanics and artificial intelligence, we can adapt the training to your skill level and help push you out of your comfort zone so that you improve faster than by playing standard video games not designed for this purpose.
              </Text>
              <Text className="mt-6">
                &emsp;We believe in providing the best possible experience for our users while balancing gameplay with performance. We strive to provide maximum value while not overlooking the individual player. After all, gaming shouldn't be a grind and you shouldn't have to fast travel alone.
              </Text>
            </Box>
            <Image
              alt="Building better gamers"
              className={`
                hidden
                lg:block
                h-96
                xxl:h-112
                max-h-96
                xxl:max-h-112
                min-h-96
                xxl:min-h-112
                rounded
                w-auto
              `}
              src={BrainwaveCircuitsImage}
            />
          </Box>
        </Box>
      </Box>
    </Section>
  )
}

export default MissionView
