import React from 'react'
import { Helmet, } from 'react-helmet'
import AimFTWImage from 'images/aim-ftw.png'
import AimFTWLogo from 'images/aim-ftw-logo.png'
import BlueVsOrangeCircuitsImage from 'images/blue-vs-orange-circuits.png'
import BrainwaveCircuitsImage from 'images/brainwave-circuits.png'
import Box from 'components/Box'
import ForgeImage from 'images/forge.png'
import Header1 from 'typography/Header1'
import Header2 from 'typography/Header2'
import Header3 from 'typography/Header3'
import HeroImage from 'images/hero.png'
import Image from 'components/Image'
import OrangeCircuitsImage from 'images/orange-circuits.png'
import PremiumImage from 'images/premium.png'
import PrimaryButton from 'components/PrimaryButton'
import RedSphereImage from 'images/red-sphere.png'
import Section from 'components/Section'
import Statistics from 'images/statistics.png'
import Text from 'typography/Text'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

const SECTIONS = [
  {
    background: 'white',
    details: [
      "Did you know that there's a better way to improve your gaming skills than by simply playing your favorite game?",
      'Using methodologies like deliberate practice you can break through skill barriers and overcome the competition.',
      'By training in this way with NetPlay for twenty to thirty minutes per day or more you should see a significant boost in your skill level compared to just playing the game.',
    ],
    id: 'how-it-works',
    image: BrainwaveCircuitsImage,
    imageAlt: 'Proper training of brainwaves leads to accelerated improvement amongst gamers.',
    listColor: 'black',
    reverse: false,
    text: 'black',
    title: 'How You Practice Matters',
  },
  {
    background: 'transparent',
    backgroundImage: BlueVsOrangeCircuitsImage,
    cover: true,
    coverClass: 'bg-black-transparent-4',
    details: [
      'Challenge your aim and game sense within multiple mini games and challenges.',
      'Match your skills against other players and vie for 1st place on the leaderboards.',
      'Receive breakdowns of your performance to see where you need to improve.',
      'As you improve, the games adapt to your skill level pushing your abilities to new heights.',
    ],
    hasBackgroundImage: true,
    id: 'aim-ftw',
    image: AimFTWLogo,
    imageAlt: 'Aim FTW™ logo',
    listColor: 'white',
    onClick: () => window.open('https://store.steampowered.com/app/1111300/Aim_FTW/', '_blank'),
    reverse: true,
    text: 'white',
    title: 'Aim FTW™ - Free on Steam!',
    titleColor: 'white',
  },
  {
    background: 'white',
    details: [
      'Take your training to the next level with Aim FTW - The Forge™, a sandbox environment where you control the gameplay.',
      'Choose your opposition: fight vicious AI-powered agents, aggressive monsters, challenging targets or all three.',
      'Train the way you want so that you can play the way you dream™!',
    ],
    id: 'forge',
    image: ForgeImage,
    imageAlt: 'Aim FTW - The Forge™',
    listColor: 'black',
    onClick: () => window.open('https://store.steampowered.com/app/1372790/Aim_FTW__The_Forge/', '_blank'),
    reverse: false,
    text: 'white',
    title: 'Enter the Forge!',
  },
  {
    background: 'transparent',
    backgroundImage: OrangeCircuitsImage,
    cover: true,
    coverClass: 'bg-black-transparent-4',
    details: [
      'Prefer a browser-based experience? Try Aim Online™, our web-based aim trainer!',
      'This top-down perspective aim trainer is perfect for training your aim for any mouse or controller-based game.',
      'Our special algorithms will adapt to your skill level as you play offering a challenging experience.',
    ],
    hasBackgroundImage: true,
    id: 'web-trainer',
    image: RedSphereImage,
    imageAlt: 'Aim Online™ red dot',
    imageClass: 'mx-auto lg:mx-0 max-w-40 sm:max-w-80 xl:max-w-none',
    listColor: 'white',
    onClick: () => window.open('https://www.netplay.life/utm_campaign=netplaygg', '_blank'),
    reverse: true,
    text: 'white',
    title: 'Aim Online™',
    titleColor: 'white',
  },
  {
    background: 'white',
    details: [
      "Purchase Aim FTW - Founder's Pack and immediately unlock new characters and monsters to use while leveling up your gameplay.",
      'Earn access to many future characters, monsters, levels, challenges and more when they release.',
      'Support future development of awesome features for Aim FTW and show your appreciation to the NetPlay team!',
    ],
    id: 'founder',
    image: PremiumImage,
    imageAlt: "Aim FTW - Founder's Pack",
    listColor: 'black',
    onClick: () => window.open('https://store.steampowered.com/app/1372791/Aim_FTW__Founders_Pack/', '_blank'),
    reverse: false,
    text: 'white',
    title: "Become a Founder!",
  },
  {
    background: 'black',
    backgroundImage: BlueVsOrangeCircuitsImage,
    cover: true,
    coverClass: 'bg-black-transparent-2',
    details: [
      'Explore detailed statistical analyses which reveal your strengths and weaknesses.',
      'Earn insights and tips into how you can best improve right now.',
      'Measure your progress over time to track your improvement.',
      'See how you compare to other players and your friends.',
    ],
    hasBackgroundImage: true,
    id: 'analysis',
    image: Statistics,
    imageAlt: "Unlock in-depth statistics and analysis with NetPlay's products",
    listColor: 'white',
    reverse: true,
    text: 'white',
    title: 'Unlock Insights and Tips',
    titleColor: 'white',
  },
]

const LandingContainer = ({ children, className, ...props }) => (
  <Box
    className={`mx-auto max-w-8xl xxl:max-w-10xl px-6 lg:px-16 xl:px-32 py-10 lg:py-24 ${className}`}
  >{children}</Box>
)

const LandingSubheader = ({ children, className, color, cover, ...props }) => (
  <Header3
    className={`
      font-medium
      font-gotham-black
      leading-none
      text-center
      ${color ? `text-${color}` : 'text-initiative-blue'}
      ${cover ? 'z-10' : ''}
      ${className}`
    }
  >{children}</Header3>
)

const LandingSection = ({
  background, backgroundImage, cover, coverClass, details, hasBackgroundImage, id, image,
  imageAlt, imageClass, listColor, onClick = () => {}, reverse, text, title, titleColor, ...props
}) => (
  <Section
    className={`bg-${background} text-${text} ${cover ? 'relative' : ''}`}
    id={id}
  >
    <Box
      className={hasBackgroundImage ? 'relative w-full' : ''}
      style={hasBackgroundImage && {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Box
        className={`
          ${cover ? 'absolute' : 'hidden'}
          ${coverClass ? coverClass: ''}
          h-full
          left-0
          top-0
          w-full
        `}
      />
      <LandingContainer
        className={`
          flex
          flex-col
          items-stretch
          sm:items-center
          lg:items-stretch
          xxl:py-32
          ${reverse ? 'lg:flex-row-reverse': 'lg:flex-row'}
          justify-around
          xxl:justify-between
          ${cover ? 'z-10' : ''}
        `}
      >
        <LandingSubheader
          className={`lg:hidden text-2xl sm:text-2xl`}
          color={titleColor}
          cover={cover}
        >
          {title}
        </LandingSubheader>
        <Image
          alt={imageAlt}
          className={`
            cursor-pointer
            self-center
            lg:h-auto
            max-h-none
            sm:max-h-60
            lg:max-h-60
            xl:max-h-80
            xxl:h-112
            mt-8
            lg:mt-0
            rounded
            w-auto
            lg:max-w-88
            xl:max-w-none
            ${cover ? 'z-10' : ''}
            ${imageClass ? imageClass : ''}
          `}
          onClick={onClick}
          src={image}
        />
        <Box className="flex flex-col justify-evenly mt-6 lg:mt-0 max-w-md xxl:max-w-xl">
          <LandingSubheader
            className={`hidden lg:block text-xl lg:text-2xl xxl:text-3xl`}
            color={titleColor}
            cover={cover}
          >
            {title}
          </LandingSubheader>
          <Box className="flex flex-col justify-center">
            {
              details.map((detail, detailIndex) => {
                return (
                  <Box
                    className={`flex items-center mt-4 xxl:mt-6 ${cover ? 'z-10' : ''}`}
                    key={`detail-${detailIndex}`}
                  >
                    <i
                      className={`
                        bg-${listColor}
                        h-2
                        min-h-2
                        mr-4
                        rounded-full
                        w-2
                        min-w-2
                      `}
                    />
                    <Text className={`font-sans leading-tight xxl:leading-normal text-base lg:text-lg xxl:text-2xl text-${listColor}`}>
                      {detail}
                    </Text>
                  </Box>
                )
              })
            }
          </Box>
        </Box>
      </LandingContainer>
    </Box>
  </Section>
)

const LandingView = () => {
  const googleAnalytics = useGoogleAnalytics()
  googleAnalytics()

  const visitSteam = (event) => {
    window.open(
      'https://store.steampowered.com/app/1111300/Aim_FTW/',
      '_blank'
    )
  }
  // <iframe
    // src="https://store.steampowered.com/widget/1111300/?t=Play%20now%20on%20Steam"
    // frameborder="0"
    // title="Steam Widget"
    // width="484"
    // height="190">
  // </iframe>
  return (
    <Section className="flex flex-col h-full min-h-screen w-full min-w-screen">
      <Helmet>
        <title>NetPlay, building better gamers™.</title>
        <link rel="canonical" href="https://www.netplay.gg/"/>
        <meta property="og:description" content="NetPlay, a data and analytics platform that uses software to bolster and improve performance among esports athletes. We combine machine learning and powerful algorithms to match the experience to each individual gamer pushing their skills to the limit."/>
        <meta property="og:image" content={HeroImage}/>
        <meta property="og:title" content="NetPlay, a data and analytics platform that uses software to bolster and improve performance among esports athletes. We combine machine learning and powerful algorithms to match the experience to each individual gamer pushing their skills to the limit."/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.netplay.gg/"/>
        <meta name="author" content="NetPlay"/>
        <meta name="description" content="NetPlay is a data and analytics platform that uses software to bolster and improve performance among esports athletes. We combine machine learning and powerful algorithms to match the experience to each individual gamer pushing their skills to the limit."/>
        <meta name="keywords" content="accuracy,aimbot,call of duty,click test,cs:go,esports,fortnite,game training,gamer,gamer training,gaming,keyboard,mechanics,mouse,mouse accuracy,mouse aim,mouse aim training,mouse exercises,mouse click test,mouse practice,mouse training games,overwatch,pubg,reflex training,training,valorant"/>
      </Helmet>
      <Box
        className="flex flex-col h-screen items-center justify-center relative w-full"
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Box className="absolute bg-black-transparent-6 xxl:bg-black-transparent-7 h-full left-0 top-0 w-full"/>
        <LandingContainer className="flex flex-col items-start ml-0 text-center">
          <Header1
            className={`
              font-medium
              xxl:font-semibold
              font-gotham-black
              leading-tight
              lg:leading-32
              xxl:mt-10
              text-6xl
              sm:text-9xl
              lg:text-12xl
              xxl:text-15xl
              text-white
              z-10
            `}
          >
            NetPlay
          </Header1>
          <Header2 className="font-gotham-medium ml-1.5 mt-4 sm:mt-2 lg:mt-6 xxl:mt-12 text-lg sm:text-2xl lg:text-4xl xxl:text-5xl text-white z-10">
            Building Better Gamers
          </Header2>
          <PrimaryButton className="hidden mt-8 lg:mt-6 xxl:mt-10 z-10" onClick={visitSteam}>
            Play now on Steam
          </PrimaryButton>
        </LandingContainer>
      </Box>
      {
        SECTIONS.map((section, sectionIndex) => (
          <LandingSection
            key={`section-${sectionIndex}`}
            onClick={section.onClick || visitSteam}
            {...section}
          />
        ))
      }
      <Section className="bg-black-xiketic text-white" id="progress">
        <Box
          className="flex flex-col h-112 xxl:h-160 items-center justify-center relative w-full"
          style={{
            backgroundImage: `url(${AimFTWImage})`,
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Box className="absolute bg-black-transparent h-full left-0 top-0 w-full"/>
          <LandingContainer className="flex flex-col items-center justify-center">
            <Header3
              className={`
                font-medium
                font-gotham-medium
                text-3xl
                lg:text-4xl
                xxl:text-5xl
                text-center
                text-white
                z-10
              `}
            >
              Don't let the competition<br className="lg:hidden"/> get a <br className="sm:hidden"/> head start!
            </Header3>
            <PrimaryButton
              className="mt-8 lg:mt-12 xxl:mt-16 w-max-content z-10"
              onClick={visitSteam}
            >
              Play now on Steam
            </PrimaryButton>
          </LandingContainer>
        </Box>
      </Section>
    </Section>
  )
}

export default LandingView
