import React from 'react'
import { Helmet, } from 'react-helmet'
import Box from 'components/Box'
import CalculatorImage from 'images/orange-circuits.png'
import Header1 from 'typography/Header1'
import HeroImage from 'images/blue-vs-orange-circuits.png'
import Image from 'components/Image'
import Section from 'components/Section'
import Text from 'typography/Text'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

const MouseSensitivityCalculatorView = () => {
  const googleAnalytics = useGoogleAnalytics()
  googleAnalytics()

  return (
    <Section>
      <Helmet>
        <title>NetPlay Mouse Sensitivity Calculator</title>
        <link rel="canonical" href="https://www.netplay.gg/mouse-sensitivity-calculator"/>
        <meta property="og:description" content="NetPlay, a data and analytics platform that uses software to bolster and improve performance among esports athletes. We combine machine learning and powerful algorithms to match the experience to each individual gamer pushing their skills to the limit."/>
        <meta property="og:image" content={HeroImage}/>
        <meta property="og:title" content="NetPlay Mouse Sensitivity Calculator"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.netplay.gg/mouse-sensitivity-calculator"/>
        <meta name="author" content="NetPlay"/>
        <meta name="description" content="NetPlay's Mouse Sensitivity Calculator. NetPlay is a data and analytics platform that uses software to bolster and improve performance among esports athletes. We combine machine learning and powerful algorithms to match the experience to each individual gamer pushing their skills to the limit."/>
        <meta name="keywords" content="accuracy,aimbot,call of duty,click test,cs:go,esports,fortnite,game training,gamer,gamer training,gaming,keyboard,mechanics,mouse,mouse accuracy,mouse aim,mouse aim training,mouse exercises,mouse click test,mouse practice,mouse training games,overwatch,pubg,reflex training,training,valorant"/>
      </Helmet>
      <Box
        className="flex flex-col h-auto min-h-screen items-center justify-center relative w-full"
        style={{
          backgroundAttachment: 'fixed',
          backgroundImage: `url(${HeroImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <Box className="absolute bg-black-transparent-3 lg:bg-black-transparent-4 h-full left-0 top-0 w-full"/>
        <Box className="mx-auto max-w-8xl xxl:max-w-10xl mt-8 sm:mt-0 px-6 sm:px-32 xxl:px-0 py-8 lg:py-24 z-10">
          <Header1
            className="font-medium font-gotham-black leading-tight sm:mt-8 lg:mt-10 xxl:mt-0 text-2xl sm:text-4xl lg:text-7xl xxl:text-9xl text-center text-white"
            // style={{ 'word-spacing': '30px' }}
          >
            NetPlay's Mouse Sensitivity Calculator
          </Header1>
          <Box className="flex items-center mt-8 lg:mt-16 xxl:mt-32">
            <Box className="lg:bg-black xxl:bg-black flex flex-1 flex-col lg:mr-12 px-8 lg:py-6 rounded xxl:text-xl max-w-2xl">
              <Text className="">
                &emsp;Coming soon...
              </Text>
              <Text className="mt-6">
                &emsp;...
              </Text>
            </Box>
            <Image
              alt="NetPlay's Mouse Sensitivity Calculator"
              className="hidden lg:block h-96 xxl:h-112 max-h-96 xxl:max-h-112 min-h-96 xxl:min-h-112 rounded w-auto"
              src={CalculatorImage}
            />
          </Box>
        </Box>
      </Box>
    </Section>
  )
}

export default MouseSensitivityCalculatorView
