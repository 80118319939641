import React from 'react'
import { Helmet, } from 'react-helmet'
import Box from 'components/Box'
import Header1 from 'typography/Header1'
import HeroImage from 'images/orange-circuits.png'
import Image from 'components/Image'
import Link from 'components/Link'
import Section from 'components/Section'
import TeamImage from 'images/team.png'
import Text from 'typography/Text'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

const TeamView = () => {
  const googleAnalytics = useGoogleAnalytics()
  googleAnalytics()

  return (
    <Section>
      <Helmet>
        <title>NetPlay Team</title>
        <link rel="canonical" href="https://www.netplay.gg/team"/>
        <meta property="og:description" content="NetPlay, a data and analytics platform that uses software to bolster and improve performance among esports athletes. We combine machine learning and powerful algorithms to match the experience to each individual gamer pushing their skills to the limit."/>
        <meta property="og:image" content={HeroImage}/>
        <meta property="og:title" content="NetPlay Team"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.netplay.gg/team"/>
        <meta name="author" content="NetPlay"/>
        <meta name="description" content="NetPlay's Team. NetPlay is a data and analytics platform that uses software to bolster and improve performance among esports athletes. We combine machine learning and powerful algorithms to match the experience to each individual gamer pushing their skills to the limit."/>
        <meta name="keywords" content="accuracy,aimbot,call of duty,click test,cs:go,esports,fortnite,game training,gamer,gamer training,gaming,keyboard,mechanics,mouse,mouse accuracy,mouse aim,mouse aim training,mouse exercises,mouse click test,mouse practice,mouse training games,overwatch,pubg,reflex training,training,valorant"/>
      </Helmet>
      <Box
        className="flex flex-col h-auto min-h-screen items-center justify-center relative w-full"
        style={{
          backgroundAttachment: 'fixed',
          backgroundImage: `url(${HeroImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <Box className="absolute bg-black-transparent-3 lg:bg-black-transparent-4 h-full left-0 top-0 w-full"/>
        <Box className="mx-auto max-w-8xl xxl:max-w-10xl mt-8 sm:mt-0 px-6 sm:px-32 xxl:px-16 py-8 lg:py-24 z-10">
          <Header1
            className="font-medium font-gotham-black leading-tight sm:mt-8 lg:mt-10 text-2xl sm:text-4xl lg:text-7xl xxl:text-9xl text-center text-white"
            // style={{ 'word-spacing': '30px' }}
          >
            NetPlay Team
          </Header1>
          <Box className="flex flex-col xxl:flex-row items-center mt-8 xxl:mt-16">
            <Box className="hidden lg:block relative rounded max-w-4xl xxl:max-w-none w-auto">
              <Box className="absolute bg-black-transparent-1 h-full left-0 top-0 w-full"/>
              <Image className="xxl:h-128 xxl:min-h-128 xxl:max-w-none xxl:min-w-192" src={TeamImage}/>
            </Box>
            <Box className="lg:bg-black xxl:bg-black flex flex-col font-gotham-book xxl:ml-12 lg:mt-8 lg:px-8 lg:py-6 rounded w-full xxl:text-xl max-w-4xl xxl:max-w-2xl">
              <Text className="">
                NetPlay is essentially a group of gamers who want to help other gamers have more fun playing video games and the best way to have more fun in our opinion is by beating the competition.
              </Text>
              <Text className="mt-6">
                We currently have two products available with more in development. With our current flagship product, <i>Aim FTW™</i>, we hope to help FPS players improve their aim thus landing more headshots, scoring more kills and winning more games. Our original product, <i>Aim Online™</i>, is designed for players of all game genres and is a quick, easy way to level up your mouse control.
              </Text>
              <Text className="mt-6">
                <i>Aim FTW™</i> is available for free on <Link className="text-primary" href="https://steamcommunity.com/app/1111300?utm_campaign=netplayggmission" target="_blank">Steam</Link> or if you are an RTS or MOBA player and/or prefer a browser-based experience, try <Link className="text-primary" href="https://www.netplay.life?utm_campaign=netplayggmission" target="_blank"><i>Aim Online™</i></Link>.
              </Text>
              <Text className="mt-6">
                NetPlay, Inc. was created in late 2016, but is the derivative of years of research, gaming and passion from our team of talented artisans and gamers who love video games and esports. The video game and esports industries are constantly evolving and we are tireless in our pursuit to deliver the best products possible.
              </Text>
              <Text className="mt-6">
                We are a globally distributed team with a developing presence around the world led by our founder, wInevitable. Interested in joining the hunt? Have a suggestion you would like to share? Drop us a line at <Link href="mailto:support@netplay.life" className="text-primary">support@netplay.life</Link>. Alternatively, sign up for our <Link href="mailto:support@netplay.life?subject=Mailing%20List" className="text-primary">mailing list</Link> to stay connected and learn about future releases.
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Section>
  )
}

export default TeamView
