import React from 'react'
import { useLocation, } from 'react-router-dom'
import Link from 'components/Link'

const NavLink = ({ children, className, ...props }) => {
  const location = useLocation()

  return (
    <Link
      className={`
        block
        font-portico
        lg:ml-12
        xxl:ml-16
        mt-6
        lg:mt-0
        hover:opacity-50
        text-lg
        lg:text-xl
        xxl:text-3xl
        text-white
        ${location.pathname === props.href || location.hash === props.href ? 'lg:underline' : ''}
        ${className}`
      }
      {...props}
    >
      {children}
    </Link>
  )
}

export default NavLink
