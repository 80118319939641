import React from 'react'
import LogoImage from 'images/logo.png'
import Image from 'components/Image'

const Logo = ({ children, ...props }) => (
  <Image alt="NetPlay logo" src={LogoImage} {...props}>
    {children}
  </Image>
)

export default Logo
