import React from 'react'
import { Helmet, } from 'react-helmet'
import HeroImage from 'images/hero.png'
import Section from 'components/Section'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

const ArticleView = () => {
  const googleAnalytics = useGoogleAnalytics()
  googleAnalytics()

  return (
    <Section>
      <Helmet>
        <title>NetPlay Blog</title>
        <link rel="canonical" href="https://www.netplay.gg/learn"/>
        <meta property="og:description" content="NetPlay, a data and analytics platform that uses software to bolster and improve performance among esports athletes. We combine machine learning and powerful algorithms to match the experience to each individual gamer pushing their skills to the limit."/>
        <meta property="og:image" content={HeroImage}/>
        <meta property="og:title" content="NetPlay Blog"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.netplay.gg/learn"/>
        <meta name="author" content="NetPlay"/>
        <meta name="description" content="NetPlay's Blog. NetPlay is a data and analytics platform that uses software to bolster and improve performance among esports athletes. We combine machine learning and powerful algorithms to match the experience to each individual gamer pushing their skills to the limit."/>
        <meta name="keywords" content="accuracy,aimbot,call of duty,click test,cs:go,esports,fortnite,game training,gamer,gamer training,gaming,keyboard,mechanics,mouse,mouse accuracy,mouse aim,mouse aim training,mouse exercises,mouse click test,mouse practice,mouse training games,overwatch,pubg,reflex training,training,valorant"/>
      </Helmet>
    </Section>
  )
}

export default ArticleView
